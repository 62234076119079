import React from "react"
import Layout from "src/components/layout"
import PortfolioHeader from "src/components/portfolioHeader"

const Design = () => (
  <Layout>
    <PortfolioHeader />
    <h1>Design</h1>
  </Layout>
)

export default Design
